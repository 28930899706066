import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import * as React from "react"
import {Layout} from "../containers/Layout";
import {PageProps} from "gatsby";
import styled from 'styled-components';
import {Palette} from "../constants";
import {Button} from "primereact/button";
import { navigate } from "gatsby"


export default function Index(props: PageProps) {
    return <StyledLayout {...props}>
        <div id={"home"}/>
        <Container>
            <div className={'hero'}>
                <p>Hey!</p>
                <p>It looks like you're leaning in to your addiction.</p>
            </div>
            <div className={'engagement'}>
                <p>How about trying something different this time?</p>
                <Button className='p-button-secondary' onClick={() => navigate('/trysomething')}>Ok. I'll try something</Button>
            </div>
        </Container>
    </StyledLayout>
}

const StyledLayout = styled(Layout)`
  background-color: #${Palette.MaximumYellowRed.hex};      
`;

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  text-align: center;
  
  .hero {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .
`;
